import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-db7283ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mali-edit__form" }
const _hoisted_2 = { class: "mali-edit__form" }
const _hoisted_3 = { class: "crm-button__group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_CrmFormLayout = _resolveComponent("CrmFormLayout")!

  return (_openBlock(), _createBlock(_component_CrmFormLayout, {
    title: _ctx.setTitleLabel(_ctx.formType, ['新建', '编辑']) + '口岸',
    onClose: _ctx.close
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        model: _ctx.formData,
        "label-position": "top",
        ref: "formRef",
        rules: _ctx.rules
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_el_row, { gutter: 80 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "口岸",
                      prop: "portName"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          onBlur: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeChinese($event, 'portName'))),
                          disabled: _ctx.formType === 2,
                          maxlength: 10,
                          modelValue: _ctx.formData.portName,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.portName) = $event)),
                          placeholder: "请输入口岸",
                          clearable: ""
                        }, null, 8, ["disabled", "modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_el_row, { gutter: 80 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "口岸备注",
                      prop: "remark"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _ctx.formData.remark,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.remark) = $event)),
                          placeholder: "请输入口岸备注",
                          maxlength: 300,
                          class: "mali-full__input",
                          type: "textarea",
                          resize: "none",
                          rows: 5
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["model", "rules"]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          class: "crm-form__button primary",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.saveFormData && _ctx.saveFormData(...args)))
        }, " 保存 ")
      ])
    ]),
    _: 1
  }, 8, ["title", "onClose"]))
}