
import { defineComponent, ref, getCurrentInstance } from 'vue';
import CrmList from '@/components/CrmList/CrmList.vue';
import PortForm from './components/PortForm.vue';
import useListData from './components/useListData';
import useVisible from '@/hooks/useVisible';
import { ajaxLoading } from '@/utils/publicMethods';
import router from '@/router';
export default defineComponent({
  name: 'PortList',
  components: { CrmList, PortForm },
  props: {},
  setup() {
    const instance = getCurrentInstance() as any;
    const { filterOptions, columnList, tableRef } = useListData(instance);
    const { visible, showVisible, formType, setFormType } = useVisible();
    const rowData = ref({});
    const refreshTable = (status = false) => {
      tableRef.value.refreshTable(status);
    };
    const rowClick = () => {
      // loadPage('', {
      // id: row.id,
      // type: 1 // 1客户 2池 3回收站
      // });
    };
    // 打开新增弹窗
    const openDrawer = (formType: number): void => {
      setFormType(formType);
      showVisible();
    };
    const setRowData = (row) => {
      rowData.value = row;
    };
    const closeVisible = (status) => {
      visible.value = false;
      // 解除重复提交
      ajaxLoading.unLock();
      if (status) {
        refreshTable(status);
      }
    };
    const getLoadPage = (url) => {
      router.push(url);
    };
    return {
      filterOptions,
      columnList,
      tableRef,
      refreshTable,
      visible,
      formType,
      showVisible,
      closeVisible,
      setFormType,
      rowClick,
      getLoadPage,
      rowData,
      setRowData,
      openDrawer,
    };
  },
});
