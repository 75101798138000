import { CrmFilterOptions, CrmTableOptions } from '@/types/type';
import { defineComponent, ref } from 'vue';
import { httpPost } from '@/api';
import {
  erpConfirm,
  successMessage,
  errorMessage,
} from '@/utils/publicMethods';
export default (Instance: any) => {
  const tableRef = ref();
  const filterOptions: CrmFilterOptions[] = [
    {
      type: 'input',
      prop: 'portName',
      value: '',
      placeholder: '请输入口岸',
    },
  ];
  const columnList: CrmTableOptions[] = [
    {
      label: '口岸',
      prop: 'portName',
      width: 151,
    },
    {
      label: '口岸备注',
      prop: 'remark',
      minWidth: 140,
    },
    {
      label: '创建人',
      prop: 'creator',
      width: 149,
    },
    {
      label: '创建时间',
      prop: 'createdTime',
      width: 232,
    },
    {
      label: '操作',
      component: defineComponent({
        template: `
          <div class="crm-btn-groups">
            <el-button v-permission="['crm:port:operatePort']" type="primary" plain @click="edit(row)">编辑</el-button>
            <el-button v-permission="['crm:port:deletePort']" type="info" plain @click="deleteRow(row)">删除</el-button>
          </div>
        `,
        props: ['row'],
        setup() {
          /** 编辑 */
          const edit = (row) => {
            Instance.setupState.rowData.value = row;
            Instance.setupState.setFormType(2);
            Instance.setupState.showVisible();
          };
          /** 删除 */
          const deleteRow = (row) => {
            Instance.setupState.rowData.value = row;
            erpConfirm('确定删除吗？')
              .then(async () => {
                const res = await httpPost('/malicrm/port/deletePort', {
                  id: row.id,
                  version: row.version,
                });
                if (res.code === 200) {
                  successMessage('已删除');
                  Instance.setupState.refreshTable(true);
                } else {
                  errorMessage(res.message);
                }
              })
              .catch(() => {
                return false;
              });
          };
          return { edit, deleteRow };
        },
      }),
      prop: '',
      fixed: 'right',
      width: 205,
    },
  ];
  return { filterOptions, columnList, tableRef };
};
