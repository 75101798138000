import { reactive, ref } from 'vue';
type FormData = {
  id: string | null; // id(添加时不需传值)
  version: string | null;
  remark: string; // 备注
  [propName: string]: any;
};
export default (params) => {
  const formRef = ref(); // 表单校验对象使用
  const formData: FormData = reactive({
    portName: null,
    remark: null,
    version: null,
    id: null,
    ...params,
  });
  const rules = reactive({
    portName: [
      { required: true, message: '请输入口岸', trigger: 'blur' },
      { max: 10, message: '最大长度为10', trigger: 'blur' },
    ],
    remark: [{ max: 300, message: '最大不能超过300', trigger: 'blur' }],
  });
  const setFormData = (paramsData: any) => {
    for (const key in formData) {
      if (Object.prototype.hasOwnProperty.call(paramsData, key)) {
        formData[key] = paramsData[key];
      }
    }
  };
  const changeChinese = (e, name) => {
    let value = e.target.value;
    value = value.replace(/[^\u4E00-\u9FA5]/g, ''); // 清除中文以外的字符
    formData[name] = value;
  };
  return { rules, formData, formRef, setFormData, changeChinese };
};
